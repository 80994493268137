import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { marketCapKeysThunk, realVolumeKeysThunk } from "../../Thunks";
import {
  ApiPutListingMarketCapKeys,
  ApiPutListingRealVolumeKeys,
} from "../../Api";
import { Loading } from "../../Components";
import { numberWithComma } from "../../Utils/helper";

const customStyles = {
  content: {
    minHeight: "274px",
    width: "551px",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    right: "auto",
    bottom: "auto",
    padding: "0",
    border: "none",
    backgroundColor: "white",
    borderRadius: "24px",
    padding: "32px 0 35px 40px",
    lineHeight: "0",
  },
};

export const ListingAssetsChange = ({
  isAboveModalOpen,
  setIsAboveModalOpen,
  aboveField,
  editableAssetsItem,
}) => {
  const dispatch = useDispatch();

  const [enteredValue, setEnteredValue] = useState(0);

  const [checkboxValue, setCheckboxValue] = useState(false);
  const [loading, serLoading] = useState(false);

  const [isValidationError, setIsValidationError] = useState(false);

  useEffect(() => {
    if (editableAssetsItem) {
      setEnteredValue(editableAssetsItem.above);
      setCheckboxValue(editableAssetsItem.alert);
    }
  }, [editableAssetsItem]);

  const acceptClick = () => {
    // const isValid = /^\d+$/.test(enteredValue);
    // const isValid = /^\d{1,3}(,\d{3})*$/.test(enteredValue);
    if (enteredValue) {
      serLoading(true);
      if (aboveField !== "circMarketCapAbove") {
        ApiPutListingRealVolumeKeys([
          {
            id: editableAssetsItem.id,
            above: enteredValue.replace(/,/g, ""),
            alert: checkboxValue,
          },
        ])
          .then(() => {
            dispatch(realVolumeKeysThunk());
            setIsValidationError(false);
            setIsAboveModalOpen(false);
            serLoading(false);
          })
          .catch(() => {
            serLoading(false);
          });
      } else {
        ApiPutListingMarketCapKeys([
          {
            id: editableAssetsItem.id,
            above: enteredValue.replace(/,/g, ""),
            alert: checkboxValue,
          },
        ])
          .then(() => {
            dispatch(marketCapKeysThunk());
            setIsValidationError(false);
            setIsAboveModalOpen(false);
            serLoading(false);
          })
          .catch(() => {
            serLoading(false);
          });
      }
    } else {
      setIsValidationError(true);
    }
  };

  const validationErrorMessage = () => {
    if (isValidationError) {
      return (
        <p className="listing_error_message">Must contain only whole numbers</p>
      );
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value.replace(/,/g, "");
    if (!isNaN(value) && value.length <= 14) {
      setEnteredValue(value);
    }
  };
  const handleInputBlur = () => {
    setEnteredValue((prevValue) => numberWithComma(prevValue));
  };
  return (
    <Modal
      isOpen={isAboveModalOpen}
      onRequestClose={() => {
        setEnteredValue(editableAssetsItem.above);
        setCheckboxValue(editableAssetsItem.alert);
        setIsAboveModalOpen(false);
      }}
      style={customStyles}
    >
      <div className="listing_asset_modal_head">
        <h2 className="listing_asset_modal_title">
          {aboveField === "volumeAbove"
            ? "Volume 24h Above"
            : aboveField === "currentVsHighAbove"
            ? "Current Price vs High Price Above"
            : aboveField === "circMarketCapAbove"
            ? "Market Cap Above"
            : aboveField === "24hVolOnRepExchangesBinanceAbove"
            ? "Rep Exchanges + Binance Vol 24h Above"
            : aboveField === "averageDailyVolumeOverThePast3MonthsAbove"
            ? "Average Daily Volume Past 3 Months Above"
            : "Reputable Exchanges Volume 24h Above"}
        </h2>

        <i
          className="icon-close listing_modal_close_icon"
          onClick={() => setIsAboveModalOpen(false)}
        ></i>
      </div>
      <div className="listing_asset_modal_money_field_wrapper">
        {validationErrorMessage()}
        <input
          type="text"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          value={enteredValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          className="listing_asset_modal_money_field"
        />
      </div>
      <div className="listing_change_alert_wrapper">
        <input
          type="checkbox"
          checked={checkboxValue}
          // onChange={()=>setCheckboxValue((val)=>!val)}
          onChange={() => setCheckboxValue((prevValue) => !prevValue)}
          className="listing_change_alert_checkbox"
        />
        <span className="listing_change_alert_text">
          {aboveField === "sufficientVolumeIn"
            ? "Alert if the 24h Volume on Reputable Exchanges below the threshold"
            : aboveField === "currentVsHighAbove"
            ? "Alert if the Current Price vs High Price below the threshold"
            : aboveField === "circMarketCapAbove"
            ? "Alert if the Market Cap below the threshold"
            : aboveField === "24hVolOnRepExchangesBinanceAbove"
            ? "Alert if the 24h Volume on Reputable Exchanges + Binance below the threshold"
            : aboveField === "averageDailyVolumeOverThePast3MonthsAbove"
            ? "Alert if the Average Daily Volume Past 3 Months below the threshold"
            : "Alert if the 24h volume below the threshold"}
        </span>
      </div>
      <div className="listing_modal_submit_wrapper">
        <button onClick={acceptClick} className="listing_modal_submit">
          ACCEPT {loading ? <Loading /> : null}
        </button>
      </div>
    </Modal>
  );
};
